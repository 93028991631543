import Swal from "sweetalert2";
import useCallableFunction from "./useCallableFunction";

const useDeleteCalls = () => async (calls, router) => {
  if (!Array.isArray(calls) && calls.length) return;

  const confirmationTitle = "Confirmation";
  const confirmationText =
    calls.length >= 2
      ? "Are you sure you want to delete the selected call(s)?"
      : "Are you sure you want to delete the call?";
  const confirmationIcon = "question";

  Swal.fire({
    title: confirmationTitle,
    text: confirmationText,
    icon: confirmationIcon,
    showCancelButton: true,
  }).then(async (confirmation) => {
    if (!confirmation.isConfirmed) return;

    const deleteCalls = useCallableFunction(
      "getDailyTranscriptions/deleteCalls"
    );

    try {
      const res = await deleteCalls(calls);
      const { errors } = res;

      const title = errors ? "Completed with errors" : "Completed Successfully";
      const text = errors
        ? "Record(s) may not be deleted completely"
        : "Record(s) have been deleted";
      const icon = errors ? "warning" : "success";

      Swal.fire({ title, text, icon }).then((result) => {
        if (result.isConfirmed) {
          const currentPath = router.currentRoute.name;
          if (currentPath === "UploadView") router.go(0);
          else router.push("/");
        }
      });
    } catch (err) {
      console.log(err);
    }
  });
};

export default useDeleteCalls;
